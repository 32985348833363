export const R = {
	"Name1": "this is my long string",
	"Bitmap1": "[base64 mime encoded serialized .NET Framework object]",
	"Icon1": "[base64 mime encoded string representing a byte array form of the .NET Framework object]",
	"BookTimeVideoCall": "If you don't have time right now, find a free time below and book your video consultation. We will send you an email with further information before the call.",
	"BookVideoConsultation_FirstAvailableCaregiver": "First available",
	"CaregiverChat_ConnectionLost_Message": "Please wait while we try to reconnect the connection or try reloading the page.",
	"CaregiverChat_MessageList_NewMessagePlaceholder": "Write message",
	"CaregiverChat_MessageList_SendButton": "Send",
	"CaregiverChat_MessageList_Title": "Chat",
	"CaregiverChat_OnDutyList_NoCaregiversLabel": "There are no caregivers available today",
	"CaregiverChat_OnDutyList_Title": "Caregivers on duty today",
	"CprRequired": "Please fill out CPR number",
	"Close": "Close",
	"FieldRequired": "Please fill out this field",
	"EmailRequired": "Email is required",
	"PhoneNumberRequired": "Please provide a phone number. We will use this to get in touch with you in case of tehcnical issues.",
	"ErrorFetchingTimetable": "An error occurred while retrieving schedule. Please try again or contact us if the problem persists.",
	"ErrorOccured": "There was an error. Please try again or contact us if the problem persists.",
	"ErrorRate": "There was an error. Failed to save rating. Please try again or contact us if the problem persists.",
	"ErrorSchedulingConsultation": "An error occurred while scheduling the consultation. Please try again or contact us if the problem persists.",
	"ErrorSendingMessage": "An error occurred while sending message. Please try again or contact us if the problem persists.",
	"InvalidCpr": "Invalid CPR number",
	"CprTooShort": "The CPR number is too short. It should be 10 digits in the format: DDMMYYXXXX",
	"CprTooOld": "Sorry, this functionality is only for children under the age of 18 years. Adults must log in with their own MitID to book a consultation.",
	"CprTooYoung": "Sorry, this functionality is only for adults over 18 years of age. Children must be created under the \"Minor children\" menu on the profile page.",
	"InvalidName": "This doesn't look like a proper full name. Please try again.",
	"InvalidEmail": "Email is invalid.",
	"PleaseChooseDate": "Select date for video consultation",
	"RequirePaymentText": "I am aware that this consultation requires payment",
	"SubjectRequired": "Subject is required",
	"ThankYouForRating": "Thank you for your review",
	"ErrorCreateConsultation": "An error occurred while creating the consultation",
	"PartnerEmailRequired": "The household member's email is required",
	"PartnerNameRequired": "The household member's name is required",
	"PartnerCprRequired": "The household member's CPR number is required",
	"Active": "Active",
	"Invited": "Invited",
	"NoPartner": "No partner",
	"AlreadyHasPartner": "Patient already has a partner",
	"EmailAlreadyExist": "The email already exists in the system",
	"ErrorCompanyData": "An error occurred while retrieving the company data",
	"CompanyNameRequired": "Company name is required",
	"ErrorCompany": "An error occurred while creating the company",
	"DeleteCompanyDescription": "When you delete the company \"{companyName}\" you will lose all its data.",
	"DeleteCompanyTitle": "Delete company",
	"ErrorDeletingCompany": "Error occurred while deleting the company",
	"DeleteCompanyWhiteListDescription": "By deleting the company's whitelist, you will not be able to restore it",
	"DeleteCompanyWhiteListTitle": "Delete the company's whitelist",
	"DomainRequired": "Domain is required",
	"FormInvalidValue": "The form contains invalid values",
	"ValidDomain": "Valid domain name should start with '@' (eg. @yahoo) Or valid email address (eg. Company@yahoo.com)",
	"ErrorDeletingDoctor": "An error occurred while deleting the caregiver",
	"ErrorGettingDoctors": "An error occurred while retrieving caregivers",
	"DoctorEmailAlreadyExist": "A user with the same email has already been created in the system. Please try again with a different email.",
	"ErrorNewDoctor": "An error occurred while creating a new caregiver",
	"CouponCodeRequired": "Coupon code must be filled in",
	"UsernameRequired": "Email is required",
	"FullNameRequired": "Name is required",
	"ErrorDeletePartner": "Could not remove partner",
	"ErrorUpdateProfileData": "Could not save profile",
	"ErrorValidatingInsuranceMembership": "An error occurred during registration. Please try again or contact us if the problem persists.",
	"InvalidPhoneNumber": "Invalid phone number",
	"PhoneCallViewModel_ViewIsClosing": "The call ends when this dialog is closed",
	"ErrorGettingPrices": "An error occurred while retrieving the prices",
	"ErrorUpdatePrice": "An error occurred while updating price",
	"InvalidPayment": "Invalid payment",
	"TimeSlotUnavailable": "Unfortunately, the time is already booked, choose another time.",
	"TooLateToReschedule": "Unfortunately, it is no longer possible to reschedule the consultation.",
	"Child": "child",
	"ErrorCompanyConsultations": "An error occurred while retrieving the company's consultations",
	"Partner": "partner",
	"ErrorPatients": "An error occurred while retrieving registered patients",
	"NoDoctorsAvailableForQueueScheduleMessage": "Find an available time below and book your video consultation. We will send you an email with further information before the call.",
	"ValidateCprInvalidCpr": "Please enter a valid CPR number. The CPR number must be exactly 10-digits long.",
	"WaitingTimeText_Other": "Waiting time is around {minutes} minutes.",
	"WaitingTimeText_Singular": "Waiting time is around {minutes} minute.",
	"Friday": "Friday",
	"Monday": "Monday",
	"Saturday": "Saturday",
	"Sunday": "Sunday",
	"Thursday": "Thursday",
	"Tuesday": "Tuesday",
	"Wednesday": "Wednesday",
	"PayButton": "Go to payment",
	"SendButton": "Send",
	"Today": "Today",
	"ErrorDeleteAccount": "An error occurred while deleting account",
	"ErrorInvitePartner": "Could not invite the family member. ",
	"ErrorCallQualityFeedback": "Could not save your feedback",
	"PleaseEnterANumber": "Please enter a number. You may use decimals (e.g. 5,2 or 5.2)",
	"PleaseEnterFullName": "Please provide your full name",
	"CouldNotUpdateChild": "Could not update child: ",
	"CouldNotCreateChild": "Could not create child: ",
	"ErrorDeleteChild": "Could not remove child: ",
	"UploadFailedTooLarge": "The file \"{0}\" is too large and therefore cannot be uploaded. Please check that your file is not larger than 30 MB.",
	"Profile_AddChild": "Add child",
	"Profile_EditChild": "Edit child profile",
	"FillOutMessage": "Please fill in a message",
	"Darn": "Sorry!",
	"SorryForYourConcerns": "We're sorry that it did not go as expected. It will be very helpful if you let us know what went wrong.",
	"ThankYou": "Thank you!",
	"ImproveOurselves": "We always want to improve. Feel free to let us know how.",
	"Great": "Great!",
	"BigHelp": "It will be a great help if you could let us know how it went.",
	"dateFnsShortDateFormat": "d MMM yyyy",
	"dateFnsTimeFormat": "HH:mm",
	"dateFnsTodayRelativeFormat": "'Today at' HH:mm",
	"dateFnsTomorrowRelativeFormat": "'Tomorrow at' HH:mm",
	"ProfileConfirmEditKid": "Save",
	"ClockValidatorError": "The time on your device is wrong. Please correct it to ensure Hejdoktor works as expected.",
	"DynamicCodeSubject": "Dynamic Code prescription",
	"dateFnsLongDateFormat": "d MMMM yyyy",
	"dateFnsLongDateTimeFormat": "d MMMM yyyy 'at' HH:mm",
	"dateFnsShortDateTimeFormat": "d MMM yyyy HH:mm",
	"NameRequired": "Please input full name",
	"AddMore": "Add more",
	"MaxFilesExceeded": "You can't add more files.",
	"InvalidFileType": "Invalid file type.",
	"TimeZoneWarning": "The times are shown in your local timezone: {timezone}",
	"Back": "Back",
	"Next": "Next",
	"PFAErrorNoAnswer": "You must select an answer option before we can proceed.",
	"SelectPlaceholder": "Choose",
	"QuestionXOfY": "Question {0} of {1}",
	"ErrorGettingCaregiverTips": "An error occurred while retrieving caregiver tips",
	"ErrorNewCaregiverTip": "An error occurred while creating a new tip",
	"Understood": "Understood",
	"PleaseEnterPetName": "Please enter the name of your pet",
	"PleaseSelectPetType": "Please select pet type",
	"PleaseSelectPetSex": "Please select sex",
	"PleaseChoosePetAge": "Please select the birthdate of your pet. It does not need to be 100% accurate.",
	"PleaseSelectMonth": "Please select month",
	"PleaseSelectYear": "Please select year",
	"CouldNotGetPets": "Could not get pets: ",
	"CouldNotCreatePet": "Could not create pet:  ",
	"CouldNotUpdatePet": "Could not update pet:  ",
	"ErrorDeletePet": "Could not remove pet: ",
	"Profile_AddPet": "Add pet",
	"Profile_EditPet": "Edit pet",
	"MonthJanuary": "January",
	"MonthFebruary": "February",
	"MonthMay": "May",
	"MonthMarch": "March",
	"MonthApril": "April",
	"MonthJune": "June",
	"MonthJuly": "July",
	"MonthAugust": "August",
	"MonthSeptember": "September",
	"MonthOctober": "October",
	"MonthNovember": "November",
	"MonthDecember": "December",
	"SelectMonth": "Select month",
	"SelectYear": "Select year",
	"PetSexMale": "Male",
	"PetSexFemale": "Female",
	"PetSexMaleNeutered": "Male neutered",
	"PetSexFemaleSterilized": "Female sterilized",
	"PetSexUnknown": "Unknown",
	"PetTypeDog": "Dog",
	"PetTypeCat": "Cat",
	"PetTypeRabbit": "Rabbit",
	"PetTypeRodent": "Rodent",
	"SelectPetType": "Select pet type",
	"SelectPetSex": "Select pet sex",
	"ErrorFetchingProfileData": "Something went wrong. Please try to refresh the page.",
	"ErrorDateInTheFuture": "You have provided a birthdate that is in the future. Please enter a valid birthdate and retry.",
	"PleaseAttachAnImageOfTheProblem": "Please attach an image of the problem",
	"ErrorTogglingNewsletter": "Could not update your newsletter preference. Our team will fix it as soon as possible. Please try again later."
}